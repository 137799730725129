import * as React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { ContactIcons } from "../components/svg";
import ContactForm from "../components/ContactForm";

const ContactPage = () => (
  <Layout ctaDisabled={true}>
    <Seo title="Contact" />
    {/* Content */}
    <div className="container max-w-screen-2xl pt-16 sm:pt-32 pb-16 sm:pb-56 sm:text-2xl">
      <h1 className="_h1">Contact us</h1>

      <div className="grid grid-cols-1 lg:grid-cols-2 sm:gap-8 mt-8 sm:mt-16">
        <div className="pb-12">
          <p>We would be happy to hear about you! Please send us an e-mail and we'll get in touch with you shortly.</p>

          <div className="mt-8 sm:mt-16 sm:text-xl">
            <div className="flex items-start">
              <ContactIcons icon="office" className="w-12 sm:w-20 mr-4 sm:mr-6 flex-none" />
              <div className="sm:mt-1">
                <p className="font-bold text-gray-800 leading-normal">Global Head Office</p>
                <p className="leading-normal">A. H. Tammsaare tee 47, Tallinn, Estonia</p>
              </div>
            </div>

            <div className="flex items-start mt-6 sm:mt-8">
              <ContactIcons icon="phone" className="w-12 sm:w-20 mr-4 sm:mr-6 flex-none" />
              <div className="sm:mt-1">
                <p className="font-bold text-gray-800 leading-normal">Phone</p>
                <p className="leading-normal">+372 6565 600</p>
              </div>
            </div>

            <div className="flex items-start mt-6 sm:mt-8">
              <ContactIcons icon="email" className="w-12 sm:w-20 mr-4 sm:mr-6 flex-none" />
              <div className="sm:mt-1">
                <p className="font-bold text-gray-800 leading-normal">For Governments / corporations</p>
                <p className="leading-normal">
                  <a className="text-blue-800" href="mailto:request@levercode.com">
                    request@levercode.com
                  </a>
                </p>

                <p className="font-bold text-gray-800 leading-normal mt-6 sm:mt-8">For general information</p>
                <p className="leading-normal">
                  <a className="text-blue-800" href="mailto:info@levercode.com">
                    info@levercode.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <ContactForm />
      </div>
    </div>
  </Layout>
);

export default ContactPage;
